<template>
  <div>

    <loading :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>

    <div class="row justify-content-md-center mt-2">
      <div class="col-12">
        <div class="card card-fullheight">
          <div class="card-body p-3">
            <h5 class="box-title mb-3 mt-1">Detalhes do Carrossel {{ this.carousel.titulo }}</h5>

              <!-- Alto -->
              <div class="row custom-row">

                <!-- Esquerda -->
                <div class="col-md-6 px-2 pb-3 text-left">
                  <div class="row mb-2">
                    <div class="col-12 p-0 m-0">
                      <small>ID</small>
                      <div class="font-weight-bold pl-2">
                        {{ carousel.id_carrossel }}
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-12 p-0 m-0">
                      <small>Título</small>
                      <div class="font-weight-bold pl-2">
                        {{ $util.formatEmpty(carousel.titulo) }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Direita -->
                <div class="col-md-6 px-2 pb-3 text-left">
                  <div class="row mb-2">
                    <div class="col-12 p-0 m-0">
                      <small>Exibindo</small>
                      <div class="font-weight-bold pl-2">
                        <span :style="{ color: formatStatusColor(carousel.dashboard) }">{{ formatStatusName(carousel.dashboard) }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-md-6 p-0 m-0">
                      <small>Ativo</small>
                      <div class="font-weight-bold pl-2">
                        <span :style="{ color: formatStatusColor(carousel.ativo) }">{{ formatStatusName(carousel.ativo) }}</span>
                      </div>
                    </div>

                    <div class="col-md-6 p-0 m-0">
                    </div>
                  </div>
                </div>
              </div>

            <hr>

            <carousel-card :value="carousel"/>

            <hr>

            <!-- Botões de ação -->
            <div class="row">
              <div class="col-12 px-0">
                <a class="btn btn-sm btn-outline-light mx-1 pull-right"
                  v-bind:href="$util.mountLink('CarouselEdit', {id: this.carousel.id_carrossel})">Editar</a>

                <a class="btn btn-sm btn-link btn-a-color mx-1 pull-right"
                  href="/dashboard/admin/carousel">Listagem</a>

                <!-- <button type="button" class="btn btn-link btn-sm mx-1 btn-a-color pull-right" @click="onShow">
                  Console
                </button> -->

                <button type="button" class="btn btn-link btn-sm btn-a-color mx-1 pull-left" @click="onToggleActivation">
                  {{ this.carousel.ativo ? 'Desativar' : 'Ativar' }}
                </button>

                <button type="button" class="btn btn-link btn-sm btn-a-color mx-1 pull-left" @click="onDelete">
                  Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import Vue from 'vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import CarouselCard from '../../CarouselCard.vue'

// Services
import DashboardService from '@/services/DashboardService'

export default {
  name: 'CarouselDetail',
  components: {
    Loading,
    CarouselCard
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      carousel: {
        id: null
      }
    }
  },
  // Apply filters to local filter
  beforeMount () {
    let _this = this
    let id = this.$route.params.id

    DashboardService.getCarousel(id).then(res => {
      this.carousel = res.data.data
    })

    _this.isLoading = false
  },
  methods: {
    onShow () {
    },
    statusColor (value) {
      return parseInt(value) ? this.colors[value] : '#000000'
    },
    formatDateTime (date) {
      return this.$util.formatDateTime(date, 'pt-BR', ' - ')
    },
    onDelete () {
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja excluir este registro?',
        'warning', 'Sim', 'Não', (e) => this.deleteCarousel(this.carousel.id)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Registro excluído com sucesso!')
          this.$router.push({ name: 'CarouselIndex' })
        } else {
          this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
        }
      })
    },
    async deleteCarousel (id) {
      let _this = this
      _this.isLoading = true
      await DashboardService.deleteCarousel(id).then(res => {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    onToggleActivation () {
      if (this.carousel.ativo && this.carousel.dashboard) {
        this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja desativar este registro?<br/>Com isso ele será retirado da tela inicial',
          'warning', 'Sim', 'Não', (e) => this.toggleActivation(false)).then((res) => {})
      } else if (this.carousel.ativo) {
        this.toggleActivation(false)
      } else {
        this.toggleActivation(true)
      }
    },
    async toggleActivation (value) {
      let _this = this
      _this.isLoading = true
      await DashboardService.toggleCarouselActivation(this.carousel.id_carrossel, value).then(res => {
        if (res.data.result) {
          this.carousel.ativo = res.data.ativo
          this.carousel.dashboard = res.data.dashboard
          this.$alertSwal.toast('Registro desativado com sucesso!')
        } else {
          this.$alertSwal.toast('O registro não foi desativado', 'error')
        }
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    formatStatus (value) {
      let statusList = [
        {
          'label': 'Não',
          'color': '#F3614E'
        },
        {
          'label': 'Sim',
          'color': '#5ec65f'
        }
      ]

      let index = 0
      if (value) {
        index = 1
      }

      return statusList[index]
    },
    formatStatusColor (value) {
      let status = this.formatStatus(value)

      return status.color
    },
    formatStatusName (value) {
      let status = this.formatStatus(value)

      return status.label
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.custom-label {
  color: grey;
}

.btn-a-color {
  color: #534B41 !important;
}
</style>
